import states from '@/assets/states'
import { form } from './text'
import { regex } from '@/js/phone-number-validation'

export default class {
	constructor(lang, stores, extended) {
		this.text = form[lang]
		this.rules = {
			required: v => !!v || this.text.errors.r,
			email: v => !!v ? (/^\S+@\S+\.\S+$/.test(v) || this.text.errors.email) : true,
			zip: v => !!v ? (/^[0-9]{5}$/.test(v) || this.text.errors.zip) : true,
			ssn: v => !!v ? (/\d{3}-\d{2}-\d{4}$/.test(v)) || this.text.errors.wrongFormat : true,
			phone: v => !!v ? (regex.test(v) || 'invalid phone number') : true,
		}
		this.fields = this.getFields(stores)
    this.surveyQsLength = !extended ? this.text.surveyQuestions.length : this.text.surveyQuestionsExtended.length
    this.surveyQuestionsRules = [v => v != undefined || this.text.errors.r]
	}

	getFields(stores) {
		return [
			// {
			// 	rowClass: null,
			// 	cols: [
			// 		{
			// 			type: 'label',
			// 			required: true,
			// 			cols: '2',
			// 			text: this.text.loc
			// 		},
			// 		{
			// 			required: true,
			// 			cols: '3',
			// 			type: 'combo',
			// 			label: this.text.select,
			// 			items: stores,
			// 			hint: this.text.locHint,
			// 			ref: 'location',
			// 			rules: [this.rules.required],
			// 			mobileHeader: this.text.loc
			// 		},
			// 		{
			// 			type: 'other',
			// 			cols: '4',
			// 			offset: '3-1',
			// 			wrongNumberBtn: true
			// 		}
			// 	]
			// },
			{
				rowClass: null,
				cols: [
					{
						type: 'label',
						required: true,
						cols: '2',
						text: this.text.ln,
					},
					{
						required: true,
						cols: '3-2',
						type: 'text',
						ref: 'lastName',
						rules: [this.rules.required],
						mobileHeader: this.text.ln
					},
					{
						required: true,
						type: 'label',
						cols: '1',
						text: this.text.fn,
						class: 'text-no-wrap ml-5',
						labelStyle: 'null$position: relative; left: 20px;'
					},
					{
						required: true,
						cols: '3-2',
						type: 'text',
						ref: 'firstName',
						rules: [this.rules.required],
						mobileHeader: this.text.fn
					},
					{
						type: 'label',
						cols: '1-2',
						text: this.text.mi,
						class: 'text-no-wrap ml-10'
					},
					{
						cols: '1-2',
						type: 'text',
						ref: 'middleInitial',
						maxlength: 1,
						mobileHeader: this.text.mi
					}
				]
			},
			{
				rowClass: null,
				cols: [
					{
						type: 'label',
						cols: '2',
						text: this.text.phone,
						labelClass: 'optional'
					},
					{
						cols: '3',
						type: 'text',
						label: '+1 (xxx) xxx-xxxx',
						maxlength: 17,
						rules: [this.rules.phone],
						ref: 'phoneNumber',
						mobileHeader: this.text.phone
					},
					{
						type: 'label',
						cols: '1-3',
						text: this.text.email,
					},
					{
						cols: '3',
						type: 'text',
						ref: 'email',
						rules: [this.rules.email],
						mobileHeader: this.text.email
					}
				]
			},
			{
				rowClass: 'dense-row first',
				cols: [
					{
						required: true,
						type: 'label',
						cols: '2',
						text: this.text.address,
						labelStyle: 'bottom: 9px;$bottom: 9px;'
					},
					{
						required: true,
						cols: '4',
						type: 'text',
						label: this.text.s1,
						ref: 'street1',
						rules: [this.rules.required],
					}
				]
			},
			{
				rowClass: 'dense-row',
				cols: [
					{
						cols: '4',
						offset: '2',
						type: 'text',
						label: this.text.s2,
						ref: 'street2',
					}
				]
			},
			{
				rowClass: 'dense-row',
				cols: [
					{
						required: true,
						cols: '4',
						offset: '2',
						type: 'text',
						label: this.text.city,
						ref: 'city',
						rules: [this.rules.required],
					}
				]
			},
			{
				rowClass: 'dense-row last',
				cols: [
					{
						required: true,
						cols: '2',
						offset: '2',
						type: 'select',
						label: this.text.state,
						items: states,
						ref: 'state',
						rules: [this.rules.required],
					},
					{
						required: true,
						cols: '2',
						type: 'text',
						label: this.text.zip,
						id: 'zipcode',
						class: 'zipcode',
						ref: 'zipcode',
						rules: [this.rules.required, this.rules.zip],
						maxlength: 5,
					}
				]
			},
			{
				rowClass: null,
				cols: [
					{
						required: true,
						type: 'label',
						cols: '2',
						text: this.text.ssn,
						class: 'text-no-wrap'
					},
					{
						required: true,
						cols: '3',
						type: 'text',
						label: 'xxx-xx-xxxx',
						id: 'ssn',
						ref: 'ssn',
						rules: [this.rules.required, this.rules.ssn],
						maxlength: 11,
						mobileHeader: this.text.ssn
					},
					{
						cols: '7',
						under40: true,
						class: 'd-flex align-center'
					}
				]
			},
			{
				rowClass: null,
				cols: [
					{
						required: true,
						type: 'label',
						cols: '2',
						text: this.text.hireDate
					},
					{
						required: true,
						cols: '2',
						type: 'date',
						ref: 'hireDate',
						rules: [this.rules.required],
						maxlength: 10,
						mobileHeader: this.text.hireDate
					}
				]
			}
		]
	}
}