<template>
	<div class="tax-credit-screening-wrapper" v-if="!!accessData">
		<div class="d-none d-lg-block text-h3 mb-10 text-center font-weight-bold form-title">{{ formName }}</div>
		<div class="d-lg-none text-h5 text-md-h4 mb-5 text-center font-weight-bold form-title">{{ formName }}</div>
		<div class="d-flex justify-center">
			<v-progress-linear
				v-if="!inputs"
	      :height="20"
	      color="info"
	      class="rounded-lg mx-5"
	      v-model="loadProgress"
	    ></v-progress-linear>
		</div>
		<div v-if="loaded">
      <form-inputs-desktop
        class="mt-10"
        :inputs="inputs"
        :text="text"
        :lang="lang"
        :formBindings="formBindings"
        :clientName="accessData.client_name"
        :loading="submitLoading"
        v-on:changeLang="changeLang"
        v-on:uploadForm="sendData"
        v-on:setLoading="setLoading"
        v-on:trySendReminder="trySendReminder"
      ></form-inputs-desktop>
       <mobile-version
          class="d-lg-none"
          :inputs="inputs"
          :text="text"
          :lang="lang"
          :formBindings="formBindings"
          :clientName="accessData.client_name"
          :loading="submitLoading"
          v-on:changeLang="changeLang"
          v-on:uploadForm="sendData"
          v-on:trySendReminder="trySendReminder"
        ></mobile-version>
    </div>
		<v-snackbar :value="!!formErr" color="error" class="d-none d-lg-flex">
			<div class="snackbar">{{ formErr }}</div>
    </v-snackbar>
	</div>
</template>

<script>
// components
import formInputsDesktop from './../components/FormInputsDesktop'
import mobileVersion from './../components/MobileVersion'
// firestore
import { accessForm } from '@/modules/tax-credit-screening/js/firebase-functions'
import { submitForm, formId } from './../js/firebase-functions'
import { sendFormReminder } from './../js/firebase-cloud-functions'
// form data
import { title, form } from './../js/text'
import formInputs from './../js/inputs'
// other js
import { parsePhone } from '@/js/phone-number-validation'
import router from '@/router/index.js'

export default {
	name: 'TaxCreditScreeningWrapper',
	components: {
		formInputsDesktop,
		mobileVersion
	},
	created: function() {
    // controll access
    // if number is not set, reroute
    if (!this.$router.history.current.query.number) {
      if (process.env.NODE_ENV == 'development') console.error("'number' must be set in route query")
      return router.push({
        name: 'Access Form', query: {
          lang: this.$router.history.current.query.lang,
          extended: this.$router.history.current.query.extended
        }})
    }
    // if number is set, check that it is valid
    accessForm(this.$router.history.current.query.number).then(res => {
      const data = res.data()
      if (data) {
        // initialize form data
        this.accessData = data
        this.inputs = new formInputs(this.lang, data.stores, this.extendedForm)
        this.inputsSetup()
        this.loaded = true
      } else {
        if (process.env.NODE_ENV == 'development') console.error('wrong number')
        router.push({ name: 'Access Form', query: {
          lang: this.$router.history.current.query.lang,
          extended: this.$router.history.current.query.extended
        }})
      }
    })
	},
	mounted: async function() {
    // loading bar
		for (let x = 0; x < 120; x++) {
			await new Promise(resolve => setTimeout(() => {
				this.loadProgress += 3
				resolve()
			}, 7))
		}
		// confirm page close
		window.onbeforeunload = () => {
			localStorage.setItem('reload', true)
		  return ''
		}
	},
	data: function() {
		return {
			// form inputs
			inputs: null,
			// monitor form
      formBindings: {
        location: this.$route.query.store,
        surveyQuestions: [],
        signature: {
          ssn: undefined,
          date: null
        },
        ssn: undefined
      },
			formErr: null,
			// other
      goingToSuccess: false,
      loaded: false,
			accessData: null,
			loadProgress: 0,
			sentFormReminder: false,
			firestoreDocId: this.idGen(true, 28), // generate id for the form reminder so that
      // we can check if this document exists in firestore
			submitLoading: false
		}
	},
	computed: {
		lang() {
			let lang = this.$route.query.lang
			if (lang != 'en' && lang != 'sp') lang = 'en'
			return lang
		},
		text() {
			return form[this.lang]
		},
		formName() {
			return title[this.lang]
		},
    extendedForm() {
      // for now, all forms should be the extended version
      return true
      // return this.$router.history.current.query.extended == 'true'
    }
	},
	methods: {
    inputsSetup() {
      // setup survey questions
      for (let i = 0; i < this.inputs.surveyQsLength; i++) {
        const key = !this.extendedForm ? 'surveyQuestions' : 'surveyQuestionsExtended'
        // full question text
        const question = !this.extendedForm ? form['en'][key][i] : form['en'][key][i].question
        let toPush = {
          question,
          val: undefined
        }
        this.formBindings.surveyQuestions.push(toPush)
      }
      // similiarly, setup followup survey question where the question text is included in the form
      if (!this.extendedForm) {
        this.formBindings.surveyQuestions[0].val = false
      } else {
        for (let i = 0; i < this.text.surveyQuestionsExtended.length; i++) {
          if (!!this.text.surveyQuestionsExtended[i].followups) {
            // need to use $set for reactivit
            this.$set(this.formBindings.surveyQuestions[i], 'followups', [])
            for (let j = 0; j < this.text.surveyQuestionsExtended[i].followups.length; j++) {
              this.formBindings.surveyQuestions[i].followups.push({
                question: form['en'].surveyQuestionsExtended[i].followups[j].text,
                val: null
              })
            }
          }
        }
      }
    },
		changeLang() {
			if (this.lang == 'en') {
				this.$router.push(this.$route.fullPath.replace('lang=en', 'lang=sp'))
			} else {
				this.$router.push(this.$route.fullPath.replace('lang=sp', 'lang=en'))
			}
			this.inputs = new formInputs(this.lang, this.accessData.stores, this.extendedForm)
		},
    setLoading(val) {
      this.submitLoading = val
    },
		async sendData() {
      this.submitLoading = true
      const form = this.formBindings
      if (!!form.phoneNumber) form.phoneNumber = parsePhone(form.phoneNumber)
			form.createdTimestamp = new Date()
			form.accessNumber = this.$route.query.number
      // get uid from metadata doc in firestore
			const uid = await formId(this.extendedForm)
			if (uid != 'err') {
				form.uid = uid
			} else {
				form.uid = parseInt(this.idGen(false, 5))
			}
      form.location = ''
			submitForm(this.firestoreDocId, form, this.extendedForm).then(() => {
				this.goingToSuccess = true
				this.$router.push({ name: 'Success Message', query: {
					lang: this.lang,
					number: this.$route.query.number,
					name: form.firstName,
					uid: form.uid,
          extended: this.extendedForm.toString()
				}})
			}).catch(err => {
        if (process.env.NODE_ENV == 'development') console.error(err)
        // will get permission denied only if rewriting doc (generated docId already exists) so just
        // generate new id and rerun function
        if (err.code == 'permission-denied') {
          this.firestoreDocId = this.idGen(true, 28)
          this.sendData()
        } else {
          this.submitLoading = false
          this.formErr = this.text.errors.unknown
        }
			})
		},
		trySendReminder(event) {
			if (this.sentFormReminder) return
			this.sentFormReminder = true
			const reminderData = {
				email: event.email,
				// phoneNumber: event.phoneNumber,
				name: event.name,
				hyperlink: process.env.VUE_APP_BASE_URL + this.$route.fullPath,
				lang: this.lang,
				docId: this.firestoreDocId
			}
			sendFormReminder(reminderData)
		},
		idGen(alphanumeric, length) {
			let result = ''
      let characters
      if (alphanumeric) {
        characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      } else {
        characters = '0123456789'
      }
		  for (let i = 0; i < length; i++) {
		  	result += characters.charAt(Math.floor(Math.random() * characters.length))
		  }
		 	return result
		}
	},
	beforeRouteLeave(to, from, next) {
		if (localStorage.getItem('reload')) {
			localStorage.removeItem('reload')
			return next()
		}
		if (this.goingToSuccess) return next()
		const answer = window.confirm('Leave page?')
	  if (answer) {
	    next()
	  } else {
	    next(false)
	  }
	}
}
</script>