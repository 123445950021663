<template>
	<div class="mobile-form">
		<div class="d-flex justify-center mt-7">
			<v-stepper class="rounded-lg mobile-stepper" v-model="stepper" v-if="loaded">
				<v-stepper-header>
					<v-stepper-step :step="stepper - 1" :complete="true" v-show="stepper > 1" color="success"></v-stepper-step>
					<v-divider v-show="stepper > 1"></v-divider>
					<v-stepper-step :step="stepper" class="m-selected"></v-stepper-step>
					<v-divider v-show="numSteps != stepper"></v-divider>
					<v-stepper-step :step="numSteps" v-show="numSteps != stepper"></v-stepper-step>
				</v-stepper-header>
				<v-stepper-items>
					<v-stepper-content v-for="(input, i) in fields" :step="i + 1" :key="i">
						<v-form :ref="`form-step-${i+1}`" @submit.prevent autocomplete="off" class="stepper-form">
              <div class="my-stepper-content">
                <div class="text-center mb-1">
  								<label :class="'font-weight-bold mobile-step-header ' + (!input.required || 'required')">{{ (input.mobileHeader || `Address - ${input.label}`) + (!input.required && input.ref != 'street2' && !input.under40 ? ` (${text.optional})` : '') }}</label>
  							</div>
  							<div class="d-flex justify-center mobile-input-div" :id="input.ref">
  								<v-combobox
  									v-if="input.type == 'combo'"
  									color="selected"
  					  			background-color="primary force"
                    class="select-directive"
    		  			    v-solo-select-error:[directiveArg(input.ref)]="testRules(input.rules, input.ref)"
  					  			dense
  									solo
  									persistent-hint
  									:label="input.label"
  									:items="input.items"
  									:hint="input.hint"
  									v-model="formBindings[input.ref]"
  									:rules="input.rules"
  									:disabled="input.disabled"
  									@keydown="checkEnter"
  								></v-combobox>
  								<v-text-field
  									v-if="(input.type == 'text' || input.type == 'date') && input.ref != 'ssn'"
  								  color="selected"
  					  		  outlined
  					  		  dense
  					  		  :type="input.type"
  					  		  :id="input.id"
  					  		  :class="input.class"
  					  		  :label="input.label"
  					  		  v-model="formBindings[input.ref]"
  					  		  :rules="input.rules"
  					  		  :maxlength="input.maxlength"
  					  		  @blur="emailEntered(input.ref)"
  					  		  @keydown="checkEnter"
                    validate-on-blur
                    :ref="input.ref"
  								></v-text-field>
  								<ssn-input
  									v-if="input.ref == 'ssn'"
  									:input="input"
  									:rules="input.rules"
                    :focus="ssnFocus"
  									v-on:update="updateSSN($event)"
  									v-on:check-enter="checkEnter($event)"
  								></ssn-input>
  								<v-select
  									v-if="input.type == 'select'"
  								  color="selected"
  								  item-color="selected"
  					  			background-color="primary force"
                    class="select-directive regular-select"
                    v-solo-select-error:[directiveArg(input.ref)]="testRules(input.rules, input.ref)"
  					  			dense
  									solo
  									:menu-props="{ bottom: true, offsetY: true }"
  									:label="input.label"
  									:items="input.items"
  									v-model="formBindings[input.ref]"
  									:rules="input.rules"
  									@keydown="checkEnter"
  								></v-select>
  								<div class="text-center" v-if="input.under40">
                    <span class="mr-2" style="position: relative; bottom: 3.5px;">{{ text.y }}</span>
                    <v-checkbox
                      color="secondary"
                      class="d-inline-block not-width-100"
                      v-model="under40"
                    ></v-checkbox>
                    <span class="mr-2 ml-5" style="position: relative; bottom: 3.5px;">{{ text.n }}</span>
                    <v-checkbox
                      color="secondary"
                      class="d-inline-block not-width-100"
                      v-model="under40No"
                      @change="over40"
                    ></v-checkbox>
  								</div>
  							</div>
              </div>
							<progress-buttons
                class="progress-btns"
								:stepper="stepper"
								:numSteps="numSteps"
								:text="text"
								v-on:previous="moveStepperBack()"
								v-on:next="moveStepper()"
							></progress-buttons>
						</v-form>
					</v-stepper-content>
					<v-stepper-content v-if="youthQuestionnareShow" :step="fields.length + 1">
            <div class="youth-questions-mobile">
              <youth-questionnare
  							:lang="lang"
  							:mobile="true"
  							v-on:checked="setYouthQuestionnare"
  						></youth-questionnare>
              <progress-buttons
                class="mt-7"
  							:stepper="stepper"
  							:submit="false"
  							:text="text"
  							v-on:previous="moveStepperBack()"
  							v-on:next="moveStepper()"
  						></progress-buttons>
            </div>
					</v-stepper-content>
          <!-- don't show first survey question in non-extended version, i starts at 1 -->
          <v-stepper-content
            v-for="i in inputs.surveyQsLength"
            v-if="!!(i - 1) || extendedForm"
            :key="`surveyQ-${i - 1}`"
            :step="getSurveyQuestionStep(i)"
          >
						<v-form :ref="`form-step-${getSurveyQuestionStep(i)}`" autocomplete="off" class="stepper-form">
              <div class="text-center my-stepper-content sq" :id="`stepper-content-${getSurveyQuestionStep(i)}`">
								<label class="mobile-step-header required">
                  {{ !extendedForm ? text.surveyQuestions[i - 1] : text.surveyQuestionsExtended[i - 1].question }}
                </label>
                <a :href="`#extended-form-${i - 1}`" class="d-hidden" :id="`link-to-extended-${i - 1}`"></a>
								<div class="d-flex justify-center mt-5">
									<v-radio-group
                    v-model="formBindings.surveyQuestions[i - 1].val"
                    :rules="inputs.surveyQuestionsRules" row class="mb-4"
                    @update:v-model="checkShowArrow"
                  >
                    <v-radio
						  				color="selected"
							  			:key="`surveyQ-${i - 1}-yes`"
							  			:label="text.y"
							  			:ripple="false"
							  			:value="true"
                      @click="scrollToExtended(extendedForm && text.surveyQuestionsExtended[i - 1].followups, i - 1)"
						  			></v-radio>
						  			<v-radio
						  				color="selected"
						  				:key="`surveyQ-${i - 1}-no`"
						  				:ripple="false"
						  				:label="text.n"
						  				:value="false"
						  			></v-radio>
						  		</v-radio-group>
								</div>
                <div
                  v-if="extendedForm && !!text.surveyQuestionsExtended[i - 1].followups && formBindings.surveyQuestions[i - 1].val"
                  class="mt-5 extended-mobile text-left"
                  :id="`extended-form-${i - 1}`"
                >
                  <div
                    class="followupsHeader mb-2 font-italic"
                    v-if="text.surveyQuestionsExtended[i - 1].followupsHeader"
                  >
                    {{ text.surveyQuestionsExtended[i - 1].followupsHeader }}
                  </div>
                  <div v-for="(question, j) in text.surveyQuestionsExtended[i - 1].followups">
                    <template v-if="question.showIfOption ? formBindings.surveyQuestions[i - 1].followups[question.showIfOption.questionIndex].val == question.showIfOption.option : true">
                      <label :for="question.text" class="surveyQ-followup required">{{ question.text }}</label>
                      <v-select
                        v-if="question.type == 'select'"
                        color="selected"
                        :menu-props="{ bottom: true, offsetY: true }"
                        placeholder="Select"
                        class="mb-2 surveyQ-text-followup"
                        :items="question.options"
                        :rules="[inputs.rules.required]"
                        v-model="formBindings.surveyQuestions[i - 1].followups[j].val"
                      ></v-select>
                      <v-text-field
                        v-if="question.type == 'text'"
                        class="surveyQ-text-followup mb-2"
                        color="selected"
                        :id="question.text"
                        :rules="[inputs.rules.required]"
                        :type="question.date ? 'date' : 'text'"
                        v-model="formBindings.surveyQuestions[i - 1].followups[j].val"
                      ></v-text-field>
                      <v-radio-group
                        dense
                        row
                        class="surveyQ-binary-followup mt-2"
                        :rules="inputs.surveyQuestionsRules"
                        v-if="question.type == 'binary'"
                        v-model="formBindings.surveyQuestions[i - 1].followups[j].val"
                      >
                        <v-radio
                          color="selected"
                          :key="`${question.text}-yes`"
                          :label="text.y"
                          :ripple="false"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          color="selected"
                          :key="`${question.text}-no`"
                          :ripple="false"
                          :label="text.n"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </template>
                  </div>
                </div>
							</div>
              <progress-buttons
                class="progress-btns"
                :stepper="stepper"
                :submit="false"
                :text="text"
                v-on:previous="moveStepperBack()"
                v-on:next="moveStepper()"
              ></progress-buttons>
              <scroll-down-arrow :showScrollArrow="showScrollArrow"></scroll-down-arrow>
						</v-form>
					</v-stepper-content>
          <!-- subtract 1 from step if regular form since not showing first survey question and add 1 because this is the step after
          all of the fields and surevey questions -->
          <v-stepper-content
            :step="fields.length + (inputs.surveyQsLength - (!extendedForm ? 1 : 0)) + (youthQuestionnareShow ? 1 : 0) + 1"
          >
            <div class="submit-step-mobile" id="mobile-submit">
              <mobile-submit
  					  	:text="text"
  					  	:signatureSsnRules="signatureSsnRules"
  					  	:loading="loading"
  					  	:clientName="clientName"
  					  	v-on:moveStepperBack="moveStepperBack()"
  					  	v-on:submit="submit"
  					  ></mobile-submit>
            </div>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</div>
		<div class="d-flex justify-center mt-5 mb-6">
			<v-btn
				color="yellow darken-1"
				elevation="2"
				rounded
				class="px-7"
				:ripple="false"
				@click="$emit('changeLang')"
			>
				{{ lang == 'en' ? 'Español' : 'English' }}
			</v-btn>
		</div>
		<div class="d-flex justify-center mb-10">
			<v-btn
				color="secondary text-capitalize"
				rounded
				@click="wrongNumber"
				class="text-subtitle-2 lang-btn"
			>
				{{ text.wc }}
			</v-btn>
		</div>
    <v-snackbar :value="error" color="error" :timeout="3000">
      <div class="snackbar">{{ text.fixError }}</div>
    </v-snackbar>
	</div>
</template>

<script>
// components
import youthQuestionnare from './YouthQuestionnare'
import mobileSubmit from './MobileSubmit'
import progressButtons from './MobileProgressButtons'
import ssnInput from '@/modules/shared/components/SSN'
import scrollDownArrow from './ScrollDownArrow'
// js
import soloSelectError from '@/modules/shared/js/solo-select-error'
import checkYouthQuestionnare from './../js/youth-questionnare'
import showScrollArrowFunction from './../js/scroll-arrow'

export default {
	name: 'MobileVersion',
	props: {
		inputs: Object,
		text: Object,
		lang: String,
    loading: Boolean,
		formBindings: Object,
    clientName: String
	},
	components: {
		progressButtons,
		ssnInput,
		youthQuestionnare,
    scrollDownArrow,
		mobileSubmit
	},
  directives: {
    soloSelectError
  },
	created: function() {
    // if there is only one store option, set the input to that store
		// const locInput = this.inputs.fields[0].cols[1]
		// if (locInput.items.length == 1) {
		// 	this.inputs.fields[0].cols[1].disabled = true
		// 	this.formBindings[locInput.ref] = locInput.items[0]
		// }
		this.genFields()
		this.loaded = true

    // for custom select error directive
    this.selectErrorDirectivePromise = new Promise(resolve => {
      this.selectErrorDirectivePromiseResolve = resolve
    })
	},
	data: function() {
		return {
			stepper: 1,
			fields: [],
			loaded: false,
			under40: false,
      dobShow: false,
      resetDOB: false,
      youthQuestionnareShow: false,
      stepSubmitted: false,
			signatureSsnRules: [this.inputs.rules.required, v => this.formBindings.ssn.slice(-4) == v || this.text.errors.finalSSN],
      selectErrorDirectivePromise: null,
      selectErrorDirectivePromiseResolve: null,
      error: false,
      showScrollArrow: false,
      ssnFocus: 0
		}
	},
	computed: {
		under40No: {
			get() {
				return !this.under40
			},
			set(val) {
				return val
			}
		},
    extendedForm() {
      // for now, all forms should be the extended version
      return true
      // return this.$router.history.current.query.extended == 'true'
    },
    numSteps() {
      let numSteps = 14
      if (this.youthQuestionnareShow) numSteps += 1
      if (this.under40) numSteps += 1
      // if (!this.formBindings.surveyQ1 || this.formBindings.surveyQ1 == 'no') numSteps += this.inputs.surveyQuestions.length - 1
      // return numSteps + 1
      numSteps += this.inputs.surveyQsLength
      if (!this.extendedForm) numSteps--
      
      return numSteps
    }
	},
  watch: {
    inputs() {
      this.genFields()
    },
    stepper(val) {
      showScrollArrowFunction(`stepper-content-${val}`, this, 'showScrollArrow')
    }
  },
	methods: {
    getSurveyQuestionStep(i) {
      // i starts at 1
      // not showing first survey question in the non-extended version -> why we have i - 1
      return this.fields.length + (!this.extendedForm ? i - 1 : i) + (this.youthQuestionnareShow ? 1 : 0)
    },
    checkShowArrow() {
      if (!this.extendedForm) return
      setTimeout(() => showScrollArrowFunction(`stepper-content-${this.stepper}`, this, 'showScrollArrow'), 500)
    },
    genFields() {
      this.fields = []
      for (const row in this.inputs.fields) {
        for (const col in this.inputs.fields[row].cols) {
          const type = this.inputs.fields[row].cols[col].type
          if (type != 'label' && type != 'other') {
            if (this.inputs.fields[row].cols[col].under40) {
              this.fields.push({
                under40: true,
                mobileHeader: this.text.under40
              })
              continue
            }
            this.fields.push(this.inputs.fields[row].cols[col])
          }
        }
      }
    },
		wrongNumber() {
			this.$router.push({ name: 'Access Form', query: this.$router.history.current.query })
		},
		checkEnter(e) {
      // for development
			if (e.keyCode == 13) this.moveStepper()
		},
		updateSSN(val) {
			this.formBindings.ssn = val
		},
		over40(val) {
      if (!val) return
      this.under40 = false
      this.formBindings.dob = null
      // set youthQuestionsShow to false
      this.resetDOB = !this.resetDOB
      this.youthQuestionnareShow = false
		},
		moveStepper() {
      this.error = false
      this.stepSubmitted = true
			if (this.youthQuestionnareShow && this.stepper == this.fields.length + 1) return this.stepper++
      if (this.stepper <= this.fields.length) {
				if (this.fields[this.stepper - 1].holdRules) this.$set(this.fields[this.stepper - 1], 'rules', this.fields[this.stepper - 1].holdRules)
				// check to see if should show youth questionnare
        this.youthQuestionnareShow = checkYouthQuestionnare({
          dob: this.formBindings.dob,
          state: this.formBindings.state
        })
        if (this.youthQuestionnareShow) this.formBindings.youthQuestionnareChecked = true
			}
			this.$nextTick(() => {
				setTimeout(() => {
					const valid = this.$refs[`form-step-${this.stepper}`][0].validate()
					if (valid) {
            if (!this.dobShow && this.under40) {
              this.dobShow = true
              this.fields.splice(this.fields.length - 1, 0, {
                mobileHeader: 'Date of Birth',
                ref: 'dob',
                type: 'date',
                required: true
              })
            } else if (this.dobShow && !this.under40) {
              this.dobShow = false
              this.fields.splice(this.fields.length - 2, 1)
            }
            this.stepSubmitted = false
						this.stepper++
            if (this.stepper < this.fields.length) this.focusInput(this.fields[this.stepper - 1].ref)
            // update solo select error directive
            if (this.stepper == 10) this.selectErrorDirectivePromiseResolve(null)
					} else {
            this.error = true
          }
				})
			})
		},
		moveStepperBack() {
      this.stepSubmitted = false
			this.stepper--
      if (this.stepper < this.fields.length) this.focusInput(this.fields[this.stepper - 1].ref)
		},
    focusInput(ref) {
      if (ref == 'ssn') {
        this.ssnFocus++
      } else {
        setTimeout(() => {
          try {
            this.$refs[ref][0].$refs.input.focus()
          } catch (err) { }
        }, 150)
      }
    },
		setYouthQuestionnare(event) {
			this.formBindings.youthQuestionnareChecked = event
		},
    directiveArg(id) {
      return {
        customSelect: false,
        firstSubmit: this.stepSubmitted,
        formBindings: this.formBindings,
        id,
        mobileVersionPromise: this.selectErrorDirectivePromise
      }
    },
    testRules(rules, ref) {
      return function (formBindings) {
        let err = false
        for (const x in rules) {
          if (rules[x](formBindings[ref]) !== true) err = true
        }

        return err
      }
    },
		emailEntered(ref) {
			if (ref != 'email' && ref != 'phoneNumber') return
			// can send multiple emails if you re-enter email eaddress
			if ((/^\S+@\S+\.\S+$/.test(this.formBindings.email))) this.$emit('trySendReminder', {
				email: this.formBindings.email,
				phoneNumber: this.formBindings.phoneNumber,
				name: this.formBindings.firstName ? `${this.formBindings.firstName} ${this.formBindings.lastName}` : null
			})
		},
    scrollToExtended(scroll, index) {
      if (!scroll) return
      this.$nextTick(() => document.getElementById(`link-to-extended-${index}`).click())
    },
		submit(signature) {
			this.formBindings.signature = signature
			this.formBindings.under40 = this.under40
			this.$emit('uploadForm')
		}
	}
}
</script>

<style scoped lang="scss">
.lang-btn {
	max-width: 95%;
	white-space: normal;
}
</style>